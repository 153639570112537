import './App.scss';

import { Navbar } from './components/navbar/navbar';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Navigation } from './components/main/navigation/navigation';
import { Contact } from './components/main/contact/contact';
import { Pricing } from './components/main/pricing/pricing';
import { MissedRoute } from './components/missedRoute/missedRoute';
import { PanelInfo } from './components/main/panelInfo/panelInfo';

import { LanguageContext } from './context/context';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Footer } from './components/footer/footer';
export enum Panels {
  coding = "coding",
  networking = "networking",
}



export interface ILanguageText {
  [language: string]: {
    navbar: {
      contact: string,
      pricing: string
    },
    panelInfo: {
      mainPage: {
        header: string,
        subtitle: string,
        subtitle2: string,
      },
      coding: {
        header: string,
        subtitle: string,
        subtitle2: string,
        arr: string[]
      },
      networking: {
        header: string,
        subtitle: string,
        subtitle2: string,
        arr: string[]
      },

    },
    contact: {
      header: string,
      email: string,
      message: string,
      send: string,
      tel: string,
      tel2: string,
      button: string,
      
    },
    pricing: {
      header: string,
      subtitle: string,
      arr: string[],
      aftermessage: string,
    }

  }
}

function App() {
  const location = useLocation();
  let language = "en"
  switch (navigator.language) {
    case "en":
      language = "en"
      break;
    case "pl":
      language = "pl"
      break;
    case "pl-PL":
      language = "pl"
      break;
    default:
      language = "en"
      break;
  }
  // var userLang = navigator.language || navigator.userLanguage;
 
  return (
    <div className="container">
      <LanguageContext.Provider value={language}>
        <Navbar />
        <TransitionGroup component={null}>
          <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <Routes>
              <Route path="/" element={< Navigation />} />
              <Route path="contact" element={< Contact />} />
              <Route path="pricing" element={< Pricing />} />
              {/* Panels from navigation */}
              <Route path="coding" element={< PanelInfo panel={Panels.coding} />} />
              <Route path="networking" element={< PanelInfo panel={Panels.networking} />} />


              <Route path="*" element={< MissedRoute />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
        <Footer />
      </LanguageContext.Provider>

    </div>
  );
}

export default App;
