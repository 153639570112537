import { Link, LinkProps, NavLink, useMatch, useResolvedPath } from "react-router-dom";

export const CustomLinkNavbar: React.FC<LinkProps> = ({ children, to, ...props }: LinkProps) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <div className="custom-link-navbar">
            <Link
                style={{ textDecoration: match ? "underline" : "none", color:"#14213dff"}}
                to={to}
                {...props}
            >
                {children}
            </Link>
            {/* {match && " (active)"} */}
        </div>
    );
}


interface ICustomLinkNavigation extends LinkProps {
    text: string
}

export const CustomLinkNavigation: React.FC<ICustomLinkNavigation> = ({ children, to, text, ...props }: ICustomLinkNavigation) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <div className="custom-link">
            <NavLink
                style={{ textDecoration: match ? "underline" : "none" }}
                to={to}
                {...props}
            >
                {text}
            </NavLink>

        </div>
    );
}
