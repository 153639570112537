import React, { useContext } from "react";
import { CustomLinkNavbar } from "../customLinks/customLinks";
import text from '../../language.json'
import './navbar.scss'
import { LanguageContext } from "../../context/context";
import { ILanguageText } from "../../App";
import logo from '../../images/logoFull.svg'
import { Link } from "react-router-dom";
export const Navbar: React.FC = () => {
    const language: string = useContext(LanguageContext)
    const navbar = text as ILanguageText
    return (<div className="container-navbar">
        <Link className="logo" to="/"><img src={logo} alt="Raftels" /></Link>
        <ul>
            <CustomLinkNavbar to="/contact">{navbar[language].navbar.contact}</CustomLinkNavbar>
            <CustomLinkNavbar to="/pricing">{navbar[language].navbar.pricing}</CustomLinkNavbar>

        </ul>

    </div>)
}


