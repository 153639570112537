import { useContext } from "react"
import { ILanguageText } from "../../../App"
import { LanguageContext } from "../../../context/context"

import text from '../../../language.json'
import './pricing.scss'
export const Pricing: React.FC = () => {
    const language = useContext(LanguageContext)
    const pricing = text as ILanguageText
    return (
        <div className="pricing-container">
            <h1>{pricing[language].pricing.header}</h1>
            <ul className="check-list">
                {pricing[language].pricing.subtitle}
                {pricing[language].pricing.arr.map((el) => <li>{el}</li>)}
            </ul>

            <h2>{pricing[language].pricing.aftermessage}</h2>
       
        </div>
    )
}