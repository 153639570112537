import { useContext, useRef, useState } from "react"
import { ILanguageText } from "../../../App"
import { LanguageContext } from "../../../context/context"
import text from '../../../language.json'
import './contact.scss'

import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha"
import React from "react"
export const Contact: React.FC = () => {
    const [captchaResp, setCaptchaResp] = useState<string | null>(null)
    const email: any = useRef();
    const message: any = useRef();
    const button: any = useRef();

    const sendEmail = (e: any) => {

        type Data = {
            serviceId: string;
            templateId: string;
            template_params: {
                from_name: any;
                message: any;
                'g-recaptcha-response': string | null;
            };
            user_id: string;
        }
        e.preventDefault();
        const data: Data = {
            serviceId: process.env.REACT_APP_SERVICE_ID!,
            templateId: process.env.REACT_APP_TEMPLATE_ID!,
            template_params: {
                'from_name': email.current.value,
                'message': message.current.value,
                'g-recaptcha-response': captchaResp
            },
            user_id: process.env.REACT_APP_USER_ID!
        };

        emailjs.send(data.serviceId, data.templateId, data.template_params, data.user_id)
            .then((result) => {
                localStorage.setItem("last-email-sent", String(Date.now()))
                email.current.value = ""
                message.current.value = ""
                button.current.value = contact[language].contact.button
                button.current.style.width = "300px"
            }, (error) => {
                console.log(error.text);
            });
    };

    const language = useContext(LanguageContext)
    const contact = text as ILanguageText
    return (
        <div className="contact-container">
            <form onSubmit={sendEmail}>
                <h1>  {contact[language].contact.header}</h1>
                <label>
                    E-mail:
                    <input type="email" placeholder={contact[language].contact.email} required name="from_name" ref={email} />
                </label>
                <label>
                    {contact[language].contact.message}:
                    <textarea placeholder={contact[language].contact.message} required name="message" ref={message} />
                </label>
                <div className="captcha">
                    <ReCAPTCHA
                        sitekey="6LdmS_UdAAAAAI609WyjcwXWdLO9CYqstFW_Bo_Q"
                        onChange={(params) => { setCaptchaResp(params) }}
                    />
                </div>

                <input type="submit" value={contact[language].contact.send} ref={button} />

                {/* <p className="padding1em">  {contact[language].contact.tel}</p> */}
                {/* <p><i className="arrow down"></i></p> */}
                {/* <p >  {contact[language].contact.tel2}</p> */}
            </form>


        </div>
    )
}