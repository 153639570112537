import React, { useContext, useEffect, useState } from "react";
import { ILanguageText, Panels } from "../../../App";
import { LanguageContext } from "../../../context/context";
import text from "../../../language.json"
import './panelInfo.scss'
type TPanelInfoProps = {
    panel: Panels;
}
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    type TWindowSize = {
        width: number | undefined,
        height: number | undefined
    }
    const [windowSize, setWindowSize] = useState<TWindowSize>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}


export const PanelInfo: React.FC<TPanelInfoProps> = ({ panel }: TPanelInfoProps) => {
    const language = useContext(LanguageContext)
    const panelInfo = text as ILanguageText
    const size = useWindowSize();
    let images = require.context('../../../images/', true);

    let dynamicImage1 = images(`./${panel}1.${panel === "coding" ? "webp" : "jpg"}`);
    let dynamicImage2 = images(`./${panel}2.${panel === "coding" ? "webp" : "jpg"}`);
    // let dynamicImage3 = images(`./${panel}3.${panel === "coding" ? "webp" : "jpg"}`);


    return (<div className="container-panel">
        <div className="info">
            <h1>{panelInfo[language].panelInfo[panel].header}</h1>
            <p>{panelInfo[language].panelInfo[panel].subtitle}</p>
        </div>
        <div className="noalign">
            <img src={dynamicImage1.default} alt={panel}></img>

        </div>
        {size.width! > 1199 && <img src={dynamicImage2.default} className="flex-start" alt={panel}></img>}

        <ul>
            <h2>{panelInfo[language].panelInfo[panel].subtitle2}</h2>
            {
                panelInfo[language].panelInfo[panel].arr.map((el) => <li>{el}</li>)
            }
        </ul>
        {/* <img src={dynamicImage3.default} alt={panel}></img> */}

    </div >)
}