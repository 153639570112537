
import { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../../../context/context'
import coding from '../../../images/coding.jpg'
import networking from '../../../images/networking.jpg'
import { CustomLinkNavigation } from '../../customLinks/customLinks'
import text from '../../../language.json'
import './navigation.scss'
import { ILanguageText } from '../../../App'

export const Navigation: React.FC = () => {
    const language = useContext(LanguageContext)
    const mainBody = text as ILanguageText
    const [animate, setAnimate] = useState<boolean>(false)
    useEffect(() => {
        setTimeout(() => {
            setAnimate(animate => !animate)
        }, 300)
    }, [])
    return (
        <div className="navigation-container" >
            <Header header={mainBody[language].panelInfo.mainPage.header} subtitle={mainBody[language].panelInfo.mainPage.subtitle} subtitle2={mainBody[language].panelInfo.mainPage.subtitle2} />
            {animate && <Panel className='left' text={mainBody[language].panelInfo.coding.header} url={"coding"} image={coding} animate={animate} />}
            {animate && <Panel className='right' text={mainBody[language].panelInfo.networking.header} url={"networking"} image={networking} animate={animate} />}

        </div>
    )
}


type TPanelProps = {
    text: string,
    className: string,
    url: string,
    image: string,
    animate: boolean,
}
const Panel: React.FC<TPanelProps> = ({ text, className, url, image, animate }: TPanelProps) => {


    return (

        <div className={animate ? `dangle${className} panel` : "panel"} style={{ "backgroundImage": `url(${image})`, "backgroundSize": "cover" }}>
            <CustomLinkNavigation to={"/" + url} text={text} ></CustomLinkNavigation>
        </div>
    )
}


type THeaderProps = {
    header: string,
    subtitle: string,
    subtitle2: string,

}
const Header: React.FC<THeaderProps> = ({ header, subtitle, subtitle2 }: THeaderProps) => {
    return (<div className='header'>

        <h1>{header}</h1>
        {window.innerWidth > 599 && <p>{subtitle}</p>}

        {window.innerWidth > 599 && <p>{subtitle2}</p>}
    </div>)
}